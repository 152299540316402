*,
::after,
::before {
  box-sizing: border-box;
}

* {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 400;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

#root,
.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  touch-action: manipulation;
}

.DesktopApp {
  width: 360px;
  height: 680px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
  touch-action: manipulation;
}

#recordSpectrum > div {
  display: inline-block;
  width: 2px;
  height: 100%;
  margin: 0 2px;
  background: currentColor;
  transform: scaleY(0.1);
  opacity: 0.25;
}

.reactour__helper {
  max-width: 360px !important;
}

.grecaptcha-badge { 
  visibility: hidden;
}
.orientationModal {
  background-color: transparent !important;
}